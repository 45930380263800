<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card>
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportCSV">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>

              <!-- button -->
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-prevent-closing
                variant="outline-primary"
                class="btn_export ml-2"
              >
                <feather-icon icon="PlusCircleIcon" />
                Ajouter un produit
              </b-button>

              <!-- modal -->
              <b-modal
                id="modal-prevent-closing"
                ref="my-modal"
                title="Nouveau produit"
                ok-title="Enregistrer"
                cancel-variant="outline-secondary"
                cancel-title="Annuler"
                size="lg"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form
                  ref="form"
                  @submit.stop.prevent="handleSubmit"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :state="produit.nomState"
                        label="Nom"
                        label-for="nom-input"
                        invalid-feedback="Le nom est requis"
                      >

                        <b-form-input
                          id="nom-input"
                          ref="nom"
                          v-model="produit.nom_produit"
                          :state="produit.nomState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="produit.prixState"
                        label="Prix"
                        label-for="price-input"
                        invalid-feedback="Le prix est requis"
                      >

                        <b-form-input
                          id="price-input"
                          ref="prix"
                          v-model="produit.prix"
                          :state="produit.prixState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="produit.prixMaxState"
                        label="Prix Max"
                        label-for="price-input"
                        invalid-feedback="Le prix Max est requis"
                      >

                        <b-form-input
                          id="price-input"
                          ref="prix"
                          v-model="produit.prix_max"
                          :state="produit.prixMaxState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="produit.typeState"
                        label="Type"
                        label-for="type-input"
                        invalid-feedback="Le type est requis"
                      >

                        <v-select
                          v-model="produit.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="typesProduit"
                          label="nom"
                        />
                      </b-form-group>
                    </b-col>
                    <!--                    <b-col md="6">-->
                    <!--                      <b-form-group-->
                    <!--                        :state="produit.parfumState"-->
                    <!--                        label="Parfum"-->
                    <!--                        label-for="scent-input"-->
                    <!--                        invalid-feedback="Le parfum est requis"-->
                    <!--                      >-->

                    <!--                        <v-select-->
                    <!--                          v-model="produit.parfum"-->
                    <!--                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                    <!--                          label="nom"-->
                    <!--                          :options="parfums"-->
                    <!--                        />-->
                    <!--                      </b-form-group>-->
                    <!--                    </b-col>-->
                  </b-row>
                </form>
              </b-modal>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <!--                  <feather-icon-->
                  <!--                    icon="Edit2Icon"-->
                  <!--                    class="mr-50"-->
                  <!--                  />-->
                  <!--                  <span>Edit</span>-->
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="handleUpdate(props.row)"
                    >
                      Modifier
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      @click="handleDelete(props.row.id)"
                    >
                      Supprimer
                    </b-button>
                  </b-button-group>

                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect, BModal,
  BPagination,
  BRow, VBModal,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import vSelect from 'vue-select'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'

export default {
  name: 'MainVue',
  components: {
    BButtonGroup,
    BModal,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
    htmlToPaper,
    'b-modal': VBModal,
  },
  data() {
    return {
      typesProduit: [],
      parfums: [],
      produit: {
        nom: '',
        prix: '',
        prix_max: '',
        type: '',
        parfum: '',
        nomState: null,
        prixState: null,
        prixMaxState: null,
        typeState: null,
        parfumState: null,
      },
      name: '',
      nameState: null,
      submittedNames: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Nom',
          field: 'nom_produit',
        },
        {
          label: 'Prix',
          field: 'prix',
        },
        {
          label: 'Prix Max',
          field: 'prix_max',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.getProduits()
    this.getTypesProduit()
    this.getParfums()
  },
  methods: {
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    checkFormValidity() {
      this.produit.typeState = !!this.produit.type
      this.produit.nomState = this.produit.nom_produit.length > 0
      this.produit.prixState = this.produit.prix >= 0
      console.log(this.produit.prixState)
      console.log(this.produit.prix)
      return this.produit.nomState && this.produit.prixState && this.produit.typeState
    },
    resetModal() {
      this.produit.id = null
      this.produit.nom_produit = ''
      this.produit.nomState = null
      this.produit.prix = ''
      this.produit.prixState = null
      this.produit.type = ''
      this.produit.typeState = null
      this.produit.parfum = ''
      this.produit.parfumState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saveProduit()
    },
    getProduits() {
      this.$http.get(`${API_URL}produits-afridia/`)
        .then(response => {
          this.rows = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getParfums() {
      this.$http.get(`${API_URL}parfum_produits/`)
        .then(response => {
          this.parfums = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTypesProduit() {
      this.$http.get(`${API_URL}type_produits/`)
        .then(response => {
          this.typesProduit = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveProduit() {
      if (!this.produit.id) {
        this.$http.post(`${API_URL}produits-afridia/`, this.produit)
          .then(response => {
            console.log(response.data)
            showToast(this, 'primary', 'Ajout', 'Element ajouté')
            this.getProduits()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$http.put(`${API_URL}produits-afridia/${this.produit.id}/`, this.produit)
          .then(response => {
            console.log(response.data)
            showToast(this, 'primary', 'Mis à jour', 'Element mis à jour')
            this.getProduits()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}produits-afridia/${id}/`)
          .then(response => {
            console.log(response.data)
            showToast(this, 'danger', 'Suppression', 'Element supprimé')
            this.getProduits()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        // Annuler la suppression ici
      }
    },
    handleUpdate(item) {
      this.produit.nom_produit = item.nom_produit
      this.produit.prix = item.prix
      this.produit.prix_max = item.prix_max
      this.produit.id = item.id
      this.produit.type = item.type
      this.produit.parfum = item.parfum
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },

}
</script>

<style scoped>

</style>
